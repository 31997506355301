// import { useState, useEffect, useCallback } from "react";

// const useLocationTracker = () => {
//   const [location, setLocation] = useState({ lat: null, long: null });
//   const [locationError, setLocationError] = useState("Not enabled");
//   const [isFetching, setIsFetching] = useState(false);

//   // Define fetchLocation using useCallback to prevent re-creation on every render
//   const fetchLocation = useCallback(() => {
//     console.log("Fetching location...");

//     setIsFetching(true);
//     if (!navigator.geolocation) {
//       setLocationError("Geolocation is not supported by your browser.");
//       console.error("Geolocation is not supported by your browser.");
//       setIsFetching(false);
//       return;
//     }

//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const newLocation = {
//           lat: position.coords.latitude,
//           long: position.coords.longitude,
//         };
//         console.log("Location in current received:", newLocation);
//         setLocation(newLocation);
//         setLocationError(null);
//         setIsFetching(false);
//       },
//       (err) => {
//         console.error("Error getting location:", err);
//         setLocationError(err.message);
//         setIsFetching(false);
//       }
//     );
//   }, []); // Empty dependency array ensures fetchLocation is stable

//   useEffect(() => {
//     console.log("Mounted - Starting location tracking");
//     fetchLocation(); // Get location immediately

//     const intervalId = setInterval(() => {
//       fetchLocation(); // Call the function every 5 seconds
//     }, 5000);

//     return () => {
//       console.log("Unmounted - Stopping location tracking");
//       clearInterval(intervalId); // Cleanup on unmount
//     };
//   }, [fetchLocation]); // Depend on fetchLocation to ensure it's stable

//   return { location, locationError, fetchLocation, isFetching };
// };

// export default useLocationTracker;


import { useState, useEffect } from "react";

export const useLocationWatcher = () => {
  const [location, setLocation] = useState(() => {
    const stored = localStorage.getItem("coordinates");
    return stored ? JSON.parse(stored) : null;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const stored = localStorage.getItem("coordinates");
      if (stored) {
        const parsed = JSON.parse(stored);
        setLocation((prevLocation) =>
          prevLocation?.lat !== parsed.lat || prevLocation?.long !== parsed.long
            ? parsed
            : prevLocation
        );
      }
      else {
        setLocation(null);
      }
    }, 500); // Check every second

    return () => clearInterval(interval);
  }, []);

  return location;
};

export const refreshCurrentLocation = () => {
  // console.log("Fetching location...");

  if (!navigator.geolocation) {
    console.error("Geolocation is not supported by this browser.");
    return;
  }

  setInterval(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log("Location received:", position.coords);
        const newLocation = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };
        // Get the previous location from local storage
        const storedLocation = localStorage.getItem("coordinates");
        const prevLocation = storedLocation ? JSON.parse(storedLocation) : null;

        // Check if the location has changed
        if (
          !prevLocation ||
          prevLocation.lat !== newLocation.lat ||
          prevLocation.long !== newLocation.long
        ) {
          console.log("Location updated:", newLocation);
          localStorage.setItem("coordinates", JSON.stringify(newLocation));
        }
      },
      (err) => {
        if (err.code === 1) {
           console.error("User denied location access");
           localStorage.removeItem("coordinates");
        }
        console.error("Error getting location:", err);
      },
      {
        enableHighAccuracy: false,
        maximumAge: 0,
      }
    );
  }, 5000);
};
