import React, { useState, useEffect } from 'react';
import { Col, Card, NavItem, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../config';
import { getStoredUser } from '../../utils/localStorageUtils';
// import images
import leftarrow from '../../images/leftarrow.svg';
const MyJob = () => {
    const params = useParams();
    const { code } = params;
    const storedUser = getStoredUser();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(false);
    const [destination, setDestination] = useState(
        localStorage.getItem("destination")
            ? localStorage.getItem("destination")
            : ""
    );
    const [driverArriving, setDriverArriving] = useState(
        localStorage.getItem("driverArriving")
            ? localStorage.getItem("driverArriving")
            : false
    );
    const clearOtp = () => {
        setOtp('')
    };
    const [journeyId, setJourneyId] = useState(
        localStorage.getItem("journeyId") ? localStorage.getItem("journeyId") : ""
    );
    const handlePinChange = (value) => {
        setOtp(value);
    };
    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setLocation({ lat: latitude, long: longitude });
            },
            (error) => {
              console.error("Error getting location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }, []);
    const date = new Date();
  
    // Adjust timezone to +03:00
    const offset = 3 * 60; // +03:00 in minutes
    
    // Convert the date to ISO string but without the Z (UTC indicator)
    const localISOTime = new Date(date.getTime() + offset * 60000).toISOString().slice(0, 16);
    
    // Add the desired timezone offset manually
    const getFormattedDateTime = () => {
        const now = new Date();
        const timezoneOffset = -now.getTimezoneOffset(); // In minutes
        const offsetHours = Math.floor(timezoneOffset / 60);
        const offsetMinutes = timezoneOffset % 60;
        const sign = offsetHours >= 0 ? "+" : "-";
        const formattedOffset = `${sign}${String(Math.abs(offsetHours)).padStart(2, '0')}:${String(Math.abs(offsetMinutes)).padStart(2, '0')}`;

        return now.toISOString().split(".")[0] + formattedOffset;
    }
    const formattedDate = getFormattedDateTime();
    const configJPin = {
        method: "POST",
        url: `${config.CustomerOnboard(journeyId)}`,
        data: { onBoardLocation: `${location.lat}, ${location.long}`, pin: otp, onBoardDateTime: formattedDate },
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            "Authorization": `Bearer ${storedUser?.token.access_token}`
        }
        // data: { onBoardLocation: `13.082679, 80.2708842`, pin },
      };
      const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        onSubmitPin(); // Call your onSubmitPin function
      };
      // API for move on Step 2
      const onSubmitPin = async () => {
        setLoading(true);
        const journeyStartResp = await axios(configJPin).catch((e) => {});
        console.log("destination reached Resp...", journeyStartResp);
        if (journeyStartResp?.status === 200) {
          setDriverArriving(false);
          localStorage.setItem("driverArriving", "false");
          setDestination(true);
          localStorage.setItem("destination", "true");
          setLoading(false);
          toast.success("Driver is onboard the customer.");
          navigate(`/at-destination/${code}`);
        }else{
            toast.error("Invalid Pin Please Try Again");
            setLoading(false);
            
        }
      };
    return (
        <>
            <div style={{ backgroundColor: '#E5E5E5', minHeight: '100vh' }} className='w-100 d-flex justify-content-center align-items-center position-relative'>
                <section className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
                    <Col className='col-11 mx-auto'>
                        <form onSubmit={handleSubmit}>
                            <Card className='' style={{ backgroundColor: '#E5E5E5', border: 'none' }}>
                                <Card.Header className='mb-5' style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                        <Link to="/d-profile"><img src={leftarrow} alt="" /></Link>
                                        <p className='m-0'><strong>Enter PIN No</strong></p>
                                    </div>
                                </Card.Header>
                                <Card.Body style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <p>Enter customer PIN to start the journey.</p>
                                    <div className="margin-top--small">
                                        <OtpInput
                                            value={otp}
                                            onChange={handlePinChange}
                                            numInputs={4}
                                            renderSeparator={<span> </span>}
                                            inputStyle="inputStyle"
                                            inputType="password"
                                            renderInput={(props) => <input {...props} />}
                                            shouldAutoFocus
                                        />
                                    </div>
                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className="btn-row mt-5">
                                        {/* <button className="btn margin-top--large" type="button" disabled={otp.trim() === ''} onClick={clearOtp}>
                                        Clear
                                    </button> */}
                                        <Button type="submit" disabled={otp.length < 4} className="loginbtn w-100">
                                            {loading ? <div className="bouncing-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> : 'Continue'}
                                        </Button>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </form>
                    </Col>
                </section>
                {/*  */}
            </div>
        </>
    )
}

export default MyJob;