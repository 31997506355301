import React, { useEffect } from 'react';
import { Col, Row, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import thankyou from '../images/thankyou.png';
import userIcon from '../images/userIcon1.svg';
import breifIcon from '../images/breifIcon.svg';
import timeIcon from '../images/timeIcon.svg';
import meetgreetIcon from '../images/meetgreetIcon.svg';
import emailicon from '../images/emailwhite.png';
import phoneicon from '../images/phonewhite.png';
import { addDays, format, parse } from 'date-fns';
const BookingConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    console.log('Location State:', data);
    window.scrollTo(0, 0);
    if (!data) {
      navigate('/');
    }
  }, [data, navigate]);

  // Check if data is undefined and return null (or a loading spinner) to prevent rendering errors
  if (!data) return null;

  const bookingDetailsData = data.bookings;
  console.log(bookingDetailsData);
  const ensurePlusSign = (number) => {
    if (number && !number.startsWith('+')) {
      return `+${number}`;
    }
    return number;
  };
    function formatBookingDate() {
        // const parsedDate = parse(dateStr, 'dd MMM-yyyy', new Date());
        // return format(parsedDate, 'dd-MM-yyyy HH:mm');
        const currentDate = new Date();
        const nextDay = addDays(currentDate, 1);
        return format(nextDay, 'dd-MM-yyyy HH:mm');
    }
  const bookAgain = (bookingDetailsData) => {
    localStorage.removeItem("booking_id");
    if (bookingDetailsData?.destination === null) {
        const pickupCoor = bookingDetailsData?.point_a;
        const [latitude, longitude] = pickupCoor.split(',').map(Number);
        const params = new URLSearchParams();
        params.append("date", formatBookingDate());
        params.append("return_date", null);
        params.append("return_pickup_time", null);
        params.append("time", '00:00');
        params.append("pickup", bookingDetailsData?.pickup);
        params.append("pickup_lat", latitude);
        params.append("pickup_lng", longitude);
        params.append("drop_lat", null);
        params.append("drop_lng", null);
        params.append("dropoff", null);
        params.append("pass", 1);
        params.append("bags", 1);
        params.append("pickup_type", "place");
        params.append("dropoff_type", "place");
        params.append("perhour", 'on');
        params.append("timebased", 1);
        params.append("booking_type", "hourly");
        params.append("booking_unique_key", "hourly");
        params.append("passenger_name", bookingDetailsData?.traveler?.passenger_name);
        params.append("passenger_email", bookingDetailsData?.traveler?.passenger_email);
        params.append("passenger_mobile", bookingDetailsData?.traveler?.passenger_mobile);
        const paramsObject = {};
        for (const [key, value] of params) {
            paramsObject[key] = value;
        }
        // Step 2: Store Object in localStorage
        localStorage.setItem('search_param', JSON.stringify(paramsObject));
        navigate("/results?" + params.toString());




        //    navigate("/results?date=" + formatBookingDate(trip?.booking_date) + "&return_date=&return_pickup_time=&time=00:00&pickup=" + trip?.pickup + "&pickup_lat="+latitude+"&pickup_lng="+longitude+"&drop_lat=&drop_lng=&dropoff=" + trip?.destination + "&pass=1&bags=1&booking_type=hourly");
    } else {
        const pickupCoor = '';
        const [latitude, longitude] = pickupCoor.split(',').map(Number);
        const destinationCoor = '';
        const [latituded, longituded] = destinationCoor.split(',').map(Number);
        const params = new URLSearchParams();
        params.append("date", formatBookingDate());
        params.append("return_date", null);
        params.append("return_pickup_time", null);
        params.append("time", '00:00');
        params.append("pickup", bookingDetailsData?.pickup);
        params.append("pickup_lat", latitude);
        params.append("pickup_lng", longitude);
        params.append("drop_lat", latituded);
        params.append("drop_lng", longituded);
        params.append("dropoff", bookingDetailsData?.destination);
        params.append("pass", 1);
        params.append("bags", 1);
        params.append("pickup_type", "place");
        params.append("dropoff_type", "place");
        params.append("booking_type", "p_to_p");
        params.append("booking_unique_key", "hourly");
        params.append("passenger_name", bookingDetailsData?.traveler?.passenger_name);
        params.append("passenger_email", bookingDetailsData?.traveler?.passenger_email);
        params.append("passenger_mobile", bookingDetailsData?.traveler?.passenger_mobile);
        const paramsObject = {};
        for (const [key, value] of params) {
            paramsObject[key] = value;
        }
        // Step 2: Store Object in localStorage
        localStorage.setItem('search_param', JSON.stringify(paramsObject));
        navigate("/results?" + params.toString());
        // navigate("/results?date=" + formatBookingDate(trip?.booking_date) + "&return_date=&return_pickup_time=&time=00:00&pickup=" + trip?.pickup + "&pickup_lat="+latitude+"&pickup_lng="+longitude+"&drop_lat="+latituded+"&drop_lng="+longituded+"&dropoff=" + trip?.destination + "&pass=1&bags=1&booking_type=p_to_p");
    }
};
  return (
    <>
      <section className='py-4 color1BG dropShadow resultPageTopRow' style={{ position: 'relative' }}>
        <Row xxl={'11'} xl={'11'} className='col-11 mx-auto'>
          <Col xxl={'11'} xl={'11'} className='mb-3 mb-xl-0'>
            <div className='d-flex align-items-center mb-5' style={{ gap: "10px" }}>
              <img src={thankyou} alt="thankyou" style={{ width: "72px" }} />
              <div>
                <b>Thank You for booking with Limowide</b>
                <p className='m-0'>A confirmation mail has been sent to your registered  E-mail address</p>
              </div>

            </div>
            <h6 className={'secondaryText fw-bold mb-4 ms-2'} style={{ fontSize: '22px' }}>Booking Summary </h6>

          </Col>
          <Col xxl={'8'} xl={'8'} className={'mb-4 mb-xl-0'}>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Booking Details</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PICKUP LOCATION</label><br />
                    <b>{bookingDetailsData?.pickup ?? ''}</b>
                  </Col>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                    <></>
                    :
                    <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                      <label>DROPOFF LOCATION</label><br />
                      <b>{bookingDetailsData?.destination}</b>
                    </Col>
                  }
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>DATE & TIME</label><br />
                    <b>{bookingDetailsData?.arrival_human}</b>
                  </Col>
                    {
                      bookingDetailsData?.return_date_time ? (
                        <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                          <label>RETURN DATE & TIME</label><br />
                          <b>{bookingDetailsData.return_date_time}</b>
                        </Col>
                      ) : null
                    }
                   {
                      bookingDetailsData?.return_booking_id ? (
                        <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                          <label>RETURN BOOKING ID</label><br />
                          <b>{bookingDetailsData.return_booking_id}</b>
                        </Col>
                      ) : null
                    }
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>FLIGHT NUMBER</label><br />
                    <b>{bookingDetailsData?.traveler?.flight || ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                      <><label>DURATION SELECTED</label><br /></> : <><label>ESTIMATED TRIP TIME</label><br /></> 
                    }
                    <b>{bookingDetailsData?.duration ? bookingDetailsData?.duration : ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                      <><label>KILOMETERS INCLUDED</label><br /></> : <><label>ESTIMATED DISTANCE</label><br /></> 
                    }
                    
                    <b>{bookingDetailsData?.distance ? `${bookingDetailsData?.distance} ` : ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>BOOKING ID</label><br />
                    <b>{bookingDetailsData?.booking_id ?? ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PIN FOR TRIP</label><br />
                    <b>{bookingDetailsData?.pin ?? ""}</b>
                  </Col>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                    <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                      <label>NOTE</label><br />
                      <b>Hourly or city tour bookings are available for local travel within city limits, with drop-offs required to be within the same boundaries.</b>
                    </Col>
                    :
                    <></>
                  }
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Car Specifications</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <b>{bookingDetailsData?.traveler?.car_name}</b><br />
                    <span>{bookingDetailsData?.traveler?.car_category.replace("_", " ")}</span>
                    <Row className='mt-4 gx-0 align-items-center justify-content-between mb-3'>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={userIcon} alt='icon' /><p className='mb-0 fw-semibold'>{bookingDetailsData?.traveler?.num_passengers + ' Passengers'}</p></div>
                      </Col>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={breifIcon} alt='icon' /><p className='mb-0 fw-semibold'>{bookingDetailsData?.traveler?.num_bags + ' Suitcases'}</p></div>
                      </Col>
                    </Row>
                    <Row className='gx-0 align-items-center justify-content-between'>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={meetgreetIcon} alt='icon' /><p className='mb-0 fw-semibold'>{'Meet & Greet'}</p></div>
                      </Col>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={timeIcon} alt='icon' /><p className='mb-0 fw-semibold'>{Number(bookingDetailsData?.traveler?.wait_time) > 0 ? `${bookingDetailsData?.traveler?.wait_time} min waiting time` : ""}</p></div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <img src={bookingDetailsData?.traveler?.fleet_image} width={'350px'} alt="car" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Passenger Details</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>NAME</label><br />
                    <b>{`${bookingDetailsData?.traveler?.passenger_name}`}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>MOBILE</label><br />
                    <b>{ensurePlusSign(bookingDetailsData?.traveler?.passenger_mobile)}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>EMAIL</label><br />
                    <b>{bookingDetailsData?.traveler?.passenger_email}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PASSENGERS/BAGS</label><br />
                    <b>{bookingDetailsData?.traveler?.num_passengers} Passenger{bookingDetailsData?.traveler?.num_passengers > 1 ? 's' : ''} / {bookingDetailsData?.traveler?.num_bags} Bag{bookingDetailsData?.traveler?.num_bags > 1 ? 's' : ''}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>SPECIFIC INSTRUCTIONS</label><br />
                    <b>{bookingDetailsData?.traveler?.special_instructions ?? ""}</b>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Cancellation Policy</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>FREE CANCELLATION BEFORE</label><br />
                    <b>{!!bookingDetailsData?.cancellationDetails ? (

                      bookingDetailsData?.cancellationDetails
                        ?.freeCancellationUntil

                    ) : (
                      'Not Cancellable'
                    )}</b>
                  </Col>
                  <Col lg={'12'} className={'mb-4 text-center d-flex justify-content-center'} style={{ fontSize: '14px' }}>
                    {
                      !bookingDetailsData?.return_date_time && (
                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => { bookAgain(bookingDetailsData); }} className='allView-btn'>Rebook</Button>
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={'3'} xl={'3'} className={'mb-4 mb-xl-0'}>
            <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Payment Summary</h6>
                <Col lg={'12'} className={'mb-4 d-flex align-items-center justify-content-between'} style={{ fontSize: '14px' }}>
                  <b>Trip Total</b>
                  <b>{bookingDetailsData?.fare}</b>
                </Col>
                <Col lg={'12'} className={'mb-4 d-flex align-items-center justify-content-between'} style={{ fontSize: '14px' }}>
                  <Button onClick={() => {
                    navigate("/booking-voucher-public", { state: bookingDetailsData });
                  }} variant={''} className={'loginbtn w-100'} > Download Voucher </Button>
                </Col>
              </Card.Body>
            </Card>
            {/*  */}
            <Card className={'darkgreenBG text-white mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
              <Card.Body className='p-5 text-center'>
                <h6 className={'text-white fw-bold mb-4'} style={{ fontSize: '26px' }}>Need Help?</h6>
                <p className='fw-bold text-white'>If you need any assisstance with regards to your booking please dont hesitate to contact us</p>

                <p className='text-white'><img src={emailicon} style={{ marginRight: "5px", width: "24px" }} /> support@limowide.com</p>
                <p className='text-white'><img src={phoneicon} style={{ marginRight: "5px", width: "24px" }} /> +44 7458 148595</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      {/*  */}
    </>
  );
};

export default BookingConfirmation;
