import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';

// Define the map container style and initial zoom
const mapContainerStyle = {
  height: '300px',
  width: '100%',
  borderRadius:'45px'
};

const Map = ({ defaultLocation, destination, origin }) => {
  // Load the Google Maps JavaScript API and other libraries
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCQ5KzDHt2llFGTexTHNMAdwfJ24XsVzK4', // Replace with your API key
    libraries: ['places'], // Include additional libraries if needed
  });

  // Handle the loading state and errors
  // if (loadError) return <div>Error loading maps</div>;
  // if (!isLoaded) return <div>Loading maps...</div>;

  const [directions, setDirections] = useState(null);

  // Create directions service instance using useCallback
  // const directionsService = useCallback(() => new window.google.maps.DirectionsService(), []);

  // Calculate the route
const calculateRoute = () => {
  console.log("Checking Google Maps API...");

  if (!window.google || !window.google.maps) {
    console.error("Google Maps API is not loaded properly.");
    return;
  }

  console.log("Google Maps API Loaded, calculating route...");

  if (!origin || !destination) {
    console.error("Origin or destination is missing", { origin, destination });
    return;
  }

  const service = new window.google.maps.DirectionsService();
  service.route(
    {
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    },
    (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        console.log("Route calculated successfully!", result);
        setDirections(result);
      } else {
        console.error(`Error fetching directions: ${status}`);
      }
    }
  );
};



  // Calculate the route when the map is loaded or when origin/destination changes
  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded, origin, destination]);
  // console.log(origin,destination, directions,"defaultLocation",defaultLocation);

  return (
    <div>
      <GoogleMap
        center={defaultLocation}
        zoom={5}
        mapContainerStyle={mapContainerStyle}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
};

export default Map;